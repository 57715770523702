@import url(./fonts/icomoon/style.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  cursor: pointer; }

html {
  height: 100%; }

body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  font-size: 14px; }

#root {
  height: 100%; }

.container {
  margin: 0 auto;
  max-width: 1180px;
  width: 100%; }

.btn-reset {
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
  color: inherit;
  padding: 0; }
  .btn-reset, .btn-reset:hover, .btn-reset:active, .btn-reset:focus {
    text-decoration: none;
    outline: none; }
  .btn-reset::-moz-focus-inner {
    border: 0; }

.tab-list {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center; }

.rc-slider-dot-active {
  border-color: red; }

.rc-slider {
  margin: 5px 5px 16px 10px; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in; }

.rc-time-picker-input {
  height: 42px !important;
  padding: 10px 15px !important;
  font-size: 14px !important; }

.rc-time-picker-panel-input-wrap {
  padding: 10px !important; }

.loader {
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease; }

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.trial-modal {
  text-align: center;
  padding: 40px; }
  @media (max-width: 600px) {
    .trial-modal {
      padding: 0 20px; } }
  .trial-modal .trial-modal-title {
    padding: 30px 0 0; }
    .trial-modal .trial-modal-title h1 {
      padding: 0 0 10px 0;
      text-transform: uppercase; }
      @media (max-width: 600px) {
        .trial-modal .trial-modal-title h1 {
          font-size: 14px; } }
    .trial-modal .trial-modal-title .trial-modal-prices {
      color: #38d4d6;
      font-size: 12px;
      line-height: 1; }
      @media (max-width: 600px) {
        .trial-modal .trial-modal-title .trial-modal-prices {
          font-size: 10px; } }
  .trial-modal .trial-modal-image-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    flex-wrap: wrap;
    text-align: left; }
    @media (max-width: 600px) {
      .trial-modal .trial-modal-image-text {
        padding: 10px 0 0; } }
    .trial-modal .trial-modal-image-text .trial-modal-image, .trial-modal .trial-modal-image-text .trial-modal-text {
      display: flex;
      flex-flow: column;
      width: 310px;
      height: 200px;
      margin: 10px;
      align-items: baseline;
      justify-content: center;
      font-size: 14px; }
    .trial-modal .trial-modal-image-text .trial-modal-image {
      background-size: cover;
      height: 200px;
      background-position: right; }
      @media (max-width: 321px) {
        .trial-modal .trial-modal-image-text .trial-modal-image {
          height: 100px;
          width: 150px; } }
    @media (max-width: 600px) {
      .trial-modal .trial-modal-image-text .trial-modal-text {
        font-size: 10px;
        text-align: left;
        height: 140px; } }
    .trial-modal .trial-modal-image-text .trial-modal-text .trial-modal-icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      text-transform: uppercase; }
      @media (max-width: 321px) {
        .trial-modal .trial-modal-image-text .trial-modal-text .trial-modal-icon-text {
          padding: 2px 0; } }
      .trial-modal .trial-modal-image-text .trial-modal-text .trial-modal-icon-text .icon-success {
        font-size: 20px;
        padding: 0 5px;
        color: #38d4d6;
        font-weight: 600; }
        @media (max-width: 321px) {
          .trial-modal .trial-modal-image-text .trial-modal-text .trial-modal-icon-text .icon-success {
            font-size: 16px; } }

.trial-modal-close {
  display: flex;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 100;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: dimgrey; }
  @media (max-width: 600px) {
    .trial-modal-close {
      font-size: 30px; } }

.a-link {
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center; }

div.main-paper {
  margin: 20px; }

body {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 24px;
  font-weight: bold; }

.nodecorate {
  text-decoration: inherit;
  color: inherit; }
