@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?m0pm5f') format('truetype'),
    url('fonts/icomoon.woff?m0pm5f') format('woff'),
    url('fonts/icomoon.svg?m0pm5f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-loudspeaker:before {
  content: "\e906";
}
.icon-pause:before {
  content: "\e901";
}
.icon-soundsuit:before {
  content: "\e60d";
}
.icon-play:before {
  content: "\e61a";
}
.icon-heart:before {
  content: "\e606";
}
.icon-user-group:before {
  content: "\e607";
}
.icon-chair:before {
  content: "\e609";
}
.icon-note-beamed:before {
  content: "\e615";
}
.icon-next:before {
  content: "\e618";
}
.icon-angle-left:before {
  content: "\e61f";
}
.icon-angle-right2:before {
  content: "\e620";
}
.icon-success:before {
  content: "\e907";
}
.icon-skip-forward:before {
  content: "\e905";
}
.icon-heart-empty:before {
  content: "\e903";
}
.icon-user-empty:before {
  content: "\e902";
}
.icon-play-empty:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-stop:before {
  content: "\e047";
}
.icon-user:before {
  content: "\e971";
}
.icon-settings:before {
  content: "\e992";
}
.icon-forward:before {
  content: "\ea20";
}
.icon-angle-right:before {
  content: "\e904";
}
